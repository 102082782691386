















import ChangePasswordForm from '@/components/forms/ChangePasswordForm.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: { ChangePasswordForm },
})
export default class ChangePassword extends Vue {}
