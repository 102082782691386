






































import { Vue, Component } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import { Form } from 'element-ui'
import { ChangePassword } from '@/types/user'
import { userAPI } from '@/api/user.api'

@Component({
  components: { InputCommon },
})
export default class ChangePasswordForm extends Vue {
  $refs!: {
    form: Form
  }

  form = {
    password: '',
    repeatPassword: '',
  }

  rules = {
    password: [
      {
        required: true,
        message: 'Поле обязательно для заполнения',
        trigger: 'blur',
      },
      {
        validator: this.handleValidatePassword,
        trigger: 'blur',
      },
    ],
    repeatPassword: [
      {
        required: true,
        message: 'Поле обязательно для заполнения',
        trigger: 'blur',
      },
      {
        validator: this.handleValidatePassword,
        trigger: 'blur',
      },
    ],
  }

  handleValidatePassword(rule, value, callback) {
    if (this.form.password === this.form.repeatPassword) {
      callback()
    }
    callback(new Error('Введеные пароли должны совпадать'))
  }

  async handleChangePassword(): Promise<void> {
    let isValid = false

    await this.$refs.form.validate(async (valid) => {
      isValid = valid
    })

    if (!isValid) {
      return
    }

    const payload: ChangePassword = {
      change_password: 'yes',
      user_checkword: this.$route.query.USER_CHECKWORD.toString(),
      user_login: this.$route.query.USER_LOGIN.toString(),
      password: this.form.password,
      password_repeat: this.form.repeatPassword,
    }

    const [error, data] = await userAPI.changePassword(payload)

    if (!error && data) {
      await this.$router.push({ name: 'Statistic' })
    }
  }
}
